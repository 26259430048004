import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/zeit/72385a0b/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Button } from './Button.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "button"
    }}>{`Button`}</h1>
    <p>{`Basic Button component`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <Playground __position={0} __code={'<Button color=\"primary\">Test</Button>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button
    }} mdxType="Playground">
  <Button color="primary" mdxType="Button">Test</Button>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Button} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      